//import { axios } from 'vue/types/umd';
import { app, apiURL } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'
export const useFarmTypesStore = defineStore('farmTypesStore', {
  state: () => ({
    farmTypes: [] as any[]
  }),
  actions: {
    async fetchFarmTypes() {
      if (this.farmTypes.length == 0) {
        try {
          const result = await app.axios.get(apiURL + '/farmTypes')
          this.farmTypes = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
